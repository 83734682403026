<template>
  <el-form
    ref="form"
    :model="GoodsOpt"
    label-width="120px"
    style="margin: 10px 0px"
  >
    <el-row>
      <el-col :span="12">
        <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
          <el-input v-model="GoodsOpt.Name"  @focus="InputSelect"></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.助记码')">
          <el-input v-model="GoodsOpt.MnemonicCode"  @focus="InputSelect"></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.条形码')">
          <el-input v-model="GoodsOpt.BarCode"  @focus="InputSelect"></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.编号')">
          <el-input v-model="GoodsOpt.Code"  @focus="InputSelect"></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.商品类型')">
          <el-select
            :placeholder="tools.GetLanguageValue('web.请选择商品类型')"
            v-model="GoodsOpt.ProductType"
          >
            <el-option
              v-for="(item, index) in ProductTypes"
              :key="index"
              :label="item.Value"
              :value="item.Key"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="GoodsOpt.ProductType == 100">
          <el-row style="margin: 5px 0px" gutter="10">
            <el-col :span="24">
              <el-form-item label="IMEI">
                <el-input v-model="GoodsOpt.IMEI"  @focus="InputSelect"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="SN">
                <el-input v-model="GoodsOpt.SN"  @focus="InputSelect"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="tools.GetLanguageValue('web.供应商')">
                <el-select
                  :placeholder="tools.GetLanguageValue('web.供应商')"
                  v-model="GoodsOpt.StoreSupplierId"
                >
                  <el-option
                    :label="item.Name"
                    :value="item.Id"
                    v-for="(item, index) in StoreSupplierList"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input />
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-form-item :label="tools.GetLanguageValue('web.分类')">
          <el-select
            :placeholder="tools.GetLanguageValue('web.请选择商品分类')"
            v-model="GoodsOpt.GoodsCategoryId"
            @change="ChangeCategory"
          >
            <el-option
              v-for="(item, index) in Categorys"
              :key="index"
              :label="item.Name"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.所属品牌')">
          <el-select
            v-model="GoodsOpt.BrandId"
            @change="GetAttributeGroupOrItemAll"
          >
            <el-option
              v-for="item in BrandDatas"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
              {{ item.Name }}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="tools.GetLanguageValue('web.单价')">
          <el-input-number v-model="GoodsOpt.Price"></el-input-number>
        </el-form-item>

        <el-form-item :label="tools.GetLanguageValue('web.成本价')">
          <el-input-number v-model="GoodsOpt.Cost"></el-input-number>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item :label="tools.GetLanguageValue('web.原价')">
          <el-input-number  @focus="InputSelect" v-model="GoodsOpt.OldPrice"></el-input-number>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.排序')">
          <el-input-number  @focus="InputSelect" v-model="GoodsOpt.Sort"></el-input-number>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
          <el-switch
            v-model="GoodsOpt.IncludeTax"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item  :label="tools.GetLanguageValue('web.税率')">
          <el-input-number v-model="GoodsOpt.TaxRate"  @focus="InputSelect"></el-input-number>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.备注')"  @focus="InputSelect">
          <el-input v-model="GoodsOpt.Description"></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.封面图')">
          <el-upload
            class="avatar-uploader"
            :action="UploadPictureImageUrl"
            :show-file-list="false"
            :on-success="UploadSuccess"
            :before-upload="UploadSuccess"
            :headers="headersImage"
          >
            <img
              v-if="GoodsOpt.ImagesFormat"
              :src="GoodsOpt.ImagesFormat"
              class="avatar"
            />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>

  <div
    class="dialog-footer"
    style="width: 100%; display: flex; justify-content: space-between"
  >
    <el-button @click="CloseOver">{{
      tools.GetLanguageValue("web.关闭")
    }}</el-button>
    <el-button @click="InsertGoods" type="primary"
      >{{ tools.GetLanguageValue("web.保存") }}
    </el-button>
  </div>

  <!-- 列表的大图 -->
  <el-dialog
    :title="tools.GetLanguageValue('web.大图')"
    v-model="ImageListDetailWindow"
    style="width: 1200px; text-align: center"
  >
    <div style="">
      <img :src="ImageListDetailUrl" style="width: 1000px" />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ImageListDetailWindow = false"
          >{{ tools.GetLanguageValue("web.关闭") }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 编辑的大图 -->
  <el-dialog
    :title="tools.GetLanguageValue('web.大图')"
    v-model="ImageEditWindow"
    style="text-align: center"
  >
    <img :src="PictureOpt.CompleteImageFormet" style="width: 1000px" />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DeletePicture" type="primary"
          >{{ tools.GetLanguageValue("web.删除") }}
        </el-button>
        <el-button @click="ImageEditWindow = false"
          >{{ tools.GetLanguageValue("web.关闭") }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
  <script setup>
import { useRouter } from "vue-router";
import axios from "../../../../commons/AxiosMethod.js";
import navigation from "../../../../components/Modules/Navigation.vue";
import { Search, Close, Refresh, Plus } from "@element-plus/icons-vue";

import tools from "../../../../commons/tools.js";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  defineComponent,
  defineProps,
  onUpdated,
  defineEmits,
  onBeforeMount,
  onMounted,
  watch,
  ref,
  computed,
} from "vue";
const clientWidth = document.body.clientWidth;
const clientHeight = document.documentElement.clientHeight;

const emit = defineEmits(["update:modelValue", "CreateOver", "CloseOver"]);

// 使用 defineProps 来声明 props

const props = defineProps({
  modelValue: {
    type: Object,
    default: {},
  },
});

const GoodsOpt = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    modelValue.value = val;
  },
});

// 定义onMounted函数
onMounted(() => {
  GetBrands();
  GetCategoryAll();
  GetProductType();
});

const InputSelect = (e) => {
  e.currentTarget.select();
};

const CloseOver = () => {
  emit("CloseOver", { Name: "123456" });
};

//插入商品
const InsertGoods = () => {
  GoodsOpt.value.SkuAttribute = [];
  axios.apiMethod(
    "/stores/GoodsManage/InsertGoods",
    "post",
    GoodsOpt.value,
    (result) => {
      if (result.Data.IsSuccess) {
        emit("CreateOver", result.Data.Model);
      } else {
        ElMessageBox({
          title: "提示",
          message: result.Data.Msg,
          type: "error",
        });
      }
    }
  );
};

const Categorys = ref({});

//获取所有分类
const GetCategoryAll = () => {
  const param = {};
  axios.apiMethod("/stores/Data/GetCategorys", "post", {}, (result) => {
    Categorys.value = result.Data;
    // GoodsOpt.value.GoodsCategoryId = result.Data[0].Id
  });
};

const ProductTypes = ref({});
//获取商品类型
const GetProductType = () => {
  ProductTypes.value = [{}];

  const param = {};
  axios.apiMethod("/sys/SystemData/GetProductType", "get", {}, (result) => {
    ProductTypes.value = result.Data;
  });
};

const ChangeCategory = (categoryId) => {
  for (var i = 0; i < Categorys.value.length; i++) {
    if (Categorys.value[i].Id == categoryId) {
      if (Categorys.value[i].TaxRate > 0) {
        GoodsOpt.value.TaxRate = Categorys.value[i].TaxRate;
        ElMessage({
          title: "提示",
          message: "税率已更改为" + GoodsOpt.value.TaxRate,
          type: "success",
        });
      }
    }
  }
};

//获取品牌
const BrandDatas = ref({});

const GetBrands = () => {
  axios.apiMethod("/stores/Data/GetProductBrands", "post", {}, (result) => {
    BrandDatas.value = result.Data;
  });
};

const AttributeGroup = ref({});

//获取SKU所有属性
const GetSKUAttributeGroupOrItemAll = () => {
  axios.apiMethod(
    "/stores/GoodsManage/GetAttributeGroupOrItemAll",
    "post",
    {
      GoodsId: GoodsOpt.value.Id,
      BrandId: GoodsOpt.value.BrandId,
      CategoryId: GoodsOpt.value.GoodsCategoryId,
    },
    (result) => {
      AttributeGroup.value = result.Data;

      AttributeGroup.value.forEach((group) => {
        for (var i = 0; i < group.ProductAttribute.length; i++) {
          GoodsOpt.value.SkuAttribute.forEach((sku) => {
            if (group.ProductAttribute[i].Id == sku.AttributeId) {
              group.OptProductAttribute = group.ProductAttribute[i].Id;
            }
          });
        }
      });
    }
  );
};

//删除阶梯价
const DeletePrecioDeVenta = (item) => {
  axios.apiMethod(
    "/stores/Goods/DeletePrecioDeVenta?precioDeVentaId=" + item.Id,
    "get",
    {},
    (result) => {
      if (result.Data.IsSuccess) {
        GetGoodsByIdDetail(GoodsOpt.value);
      }
    }
  );
};

const PrecioDeVentaVal = ref(0);

//添加
const AddPrecioDeVentaVal = (item) => {
  if (PrecioDeVentaVal.value > 0) {
    for (var i = 0; item.PrecioDeVentas.length > i; i++) {
      if (item.PrecioDeVentas[i].Price == PrecioDeVentaVal.value) {
        PrecioDeVentaVal.value = 0;
        return;
      }
    }
    item.PrecioDeVentas.push({
      Price: PrecioDeVentaVal.value,
      Id: "00000000-0000-0000-0000-000000000000",
    });
    PrecioDeVentaVal.value = 0;
  }
};
</script>
  <style>
</style>
  