<template>
  <navigation index="marketQuick" ref="navigation">
    <div
      class="topnavigation"
      style="gap: 10px; justify-content: space-between; width: calc(100% - 5px)"
    >
      <div style="min-width: 600px">
        <el-button type="info" @click="StoreSettingsWindow = true">
          <el-icon class="el-icon--right">
            <Setting />
          </el-icon>
          {{ tools.GetLanguageValue("web.设置") }}
        </el-button>
        <el-button type="primary" @click="GoPage('marketList')">
          <el-icon class="el-icon--right"><DocumentCopy /></el-icon>
          {{ tools.GetLanguageValue("web.销售工作台") }}
        </el-button>
        <el-button @click="GoPage('productList')">
          <el-icon class="el-icon--right"><Goods /></el-icon>

          {{ tools.GetLanguageValue("web.商品管理") }}
        </el-button>
        <el-button @click="OpenQuickCreateGoodsWindow()" type="primary">
          <el-icon class="el-icon--right">
            <svg
              t="1711133893488"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4294"
              width="200"
              height="200"
            >
              <path
                d="M770.528395 935.556741v-87.381334h-87.330765c-15.448494 0-28.077827-12.641975-28.077828-28.077827s12.629333-28.077827 28.077828-28.077827h87.330765v-87.330765c0-15.435852 12.629333-28.077827 28.077827-28.077828 15.435852 0 28.077827 12.641975 28.077827 28.077828v87.330765H852.069136l-34.613729-532.088099c-1.251556-19.241086-16.346074-34.196543-34.550518-34.196543h-129.327408C653.577481 104.068741 560.519901 5.436049 445.718123 5.436049S237.858765 104.068741 237.858765 225.735111H108.493432c-18.204444 0-33.298963 14.955457-34.550518 34.196543L32.527802 896.353975a41.364543 41.364543 0 0 0-0.075851 2.503111c0 20.277728 15.499062 36.712296 34.62637 36.724939H770.528395v-0.025284zM445.69284 78.885926c76.534519 0 138.581333 65.750914 138.581333 146.861827H307.111506c0-81.110914 62.046815-146.861827 138.581334-146.861827z m0 660.884543c-88.506469 0.06321-170.666667-48.760099-216.797235-128.809086a38.431605 38.431605 0 0 1-5.714173-20.201877c0-20.277728 15.511704-36.712296 34.651654-36.699654h0.025284c12.490272 0 24.007111 7.10479 30.189037 18.596346 33.551802 58.216296 93.297778 93.740247 157.670716 93.689679 64.372938 0.025284 124.118914-35.498667 157.658075-93.714963 9.98716-17.306864 31.314173-22.742914 47.647604-12.161581s21.466074 33.197827 11.466272 50.49205c-44.638815 77.255111-125.029136 128.809086-216.797234 128.809086zM826.684049 848.175407v87.343408c19.089383-1.403259 33.488593-18.925037 32.173828-39.16484l-3.13521-48.19121h-29.038618z"
                p-id="4295"
                fill="#ffffff"
              ></path>
              <path
                d="M941.612247 677.091556c-78.987062-78.987062-207.062914-78.987062-286.024691 0-78.987062 78.97442-78.987062 207.062914 0 286.075259 78.961778 78.97442 207.03763 78.97442 286.062617 0s78.97442-207.10084-0.037926-286.075259z m-27.610074 171.083851h-87.318124v87.343408c0 15.473778-12.641975 28.103111-28.077827 28.103111a28.153679 28.153679 0 0 1-28.077827-28.065185v-87.381334h-87.330765c-15.448494 0-28.077827-12.641975-28.077828-28.077827s12.629333-28.077827 28.077828-28.077827h87.330765v-87.330765c0-15.435852 12.629333-28.077827 28.077827-28.077828 15.435852 0 28.077827 12.641975 28.077827 28.077828v87.330765h87.318124c15.448494 0 28.065185 12.641975 28.065185 28.077827s-12.616691 28.077827-28.065185 28.077827z"
                p-id="4296"
                fill="#ffffff"
              ></path>
            </svg>
          </el-icon>
          {{ tools.GetLanguageValue("web.快速添加") }}
        </el-button>

        <el-button type="warning" @click="GoPage('storagesManage')">
          <el-icon class="el-icon--right"><Box /></el-icon>
          {{ tools.GetLanguageValue("web.库存管理") }}
        </el-button>
      </div>

      <div style="display: flex; justify-content: center">
        <el-button
          type="success"
          @click="
            () => {
              MakertOpt = {
                OrderType: 0,
                QuickProducts: [],
              };
            }
          "
        >
          <el-icon class="el-icon--right"><CirclePlus /></el-icon>

          {{ tools.GetLanguageValue("web.新建") }}</el-button
        >

        <el-button
          style="margin-right: 10px"
          @click="OpenMakertListWindow()"
          type="danger"
        >
          <el-icon class="el-icon--right"><Tickets /></el-icon>
          {{ tools.GetLanguageValue("web.订单管理") }}
        </el-button>

        <el-button
          style="margin-right: 10px"
          @click="GetHangUpMakertList()"
          type="warning"
        >
          <el-icon class="el-icon--right"><Link /></el-icon>
          {{ tools.GetLanguageValue("web.挂单管理") }}
        </el-button>

        <el-icon
          @click="CheckHintWindow = true"
          color="#fff"
          style="font-size: 18pt; margin-right: 15px"
          ><InfoFilled
        /></el-icon>
      </div>
    </div>

    <div
      :style="{ height: ContentHeight + 'px' }"
      style="display: flex; justify-content: space-between; width: 100%"
    >
      <div style="width: calc(100% - 355px)">
        <div style="height: calc(100% - 230px)">
          <el-table
            border
            :data="MakertOpt.OrderItems"
            style="width: calc(100% - 10px); height: 100%"
          >
            <el-table-column type="index" width="50"> </el-table-column>

            <el-table-column
              :label="tools.GetLanguageValue('web.商品名称')"
              width="220"
            >
              <template #default="scope">
                <span v-if="scope.row.IsTemp">
                  <input
                    style="height: 30px; border: 0px; width: 100%; color: red"
                    v-model="scope.row.Name"
                    @blur="UpdateMarketOrderItem(scope.row)"
                    @keyup.enter="UpdateMarketOrderItem(scope.row)"
                    @focus="InputSelect"
                  />
                </span>
                <span v-else>{{ scope.row.Name }} </span>
                <div
                  v-if="
                    scope.row.ProductType == 100 &&
                    scope.row.StorageTakeNote != null
                  "
                >
                  <div>IMEI:{{ scope.row.StorageTakeNote.IMEI }}</div>
                  <div>SN:{{ scope.row.StorageTakeNote.SN }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="tools.GetLanguageValue('web.单价')"
              width="90"
            >
              <template #default="scope">
                <input
                  style="height: 30px; border: 0px"
                  v-model.number="scope.row.Price"
                  @blur="UpdateMarketOrderItem(scope.row)"
                  @keyup.enter="UpdateMarketOrderItem(scope.row)"
                  @focus="InputSelect"
                />
              </template>
            </el-table-column>

            <el-table-column
              :label="tools.GetLanguageValue('web.数量')"
              width="80"
            >
              <template #default="scope">
                <input
                  style="height: 30px; border: 0px"
                  v-model.number="scope.row.Count"
                  @blur="UpdateMarketOrderItem(scope.row)"
                  @keyup.enter="UpdateMarketOrderItem(scope.row)"
                  @focus="InputSelect"
                />
              </template>
            </el-table-column>

            <el-table-column
              :label="tools.GetLanguageValue('web.金额')"
              width="90"
              prop="AmountFormate"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.税率')"
              width="70"
            >
              <template #default="scope">
                <input
                  style="height: 30px; border: 0px"
                  v-model.number="scope.row.TaxRate"
                  @focus="InputSelect"
                  @blur="UpdateMarketOrderItem(scope.row)"
                  @keyup.enter="UpdateMarketOrderItem(scope.row)"
                />
              </template>
            </el-table-column>
            <!-- <el-table-column
              :label="tools.GetLanguageValue('web.税率')"
              width="100"
              prop="TaxRate"
            /> -->
            <el-table-column
              :label="tools.GetLanguageValue('web.税额')"
              width="90"
              prop="TaxAmountFormate"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.合计')"
              width="100"
              prop="TotalFormate"
            />

            <el-table-column
              :label="tools.GetLanguageValue('web.创建时间')"
              width="180"
              prop="CreateTimeFormat"
            />

            <el-table-column
              :label="tools.GetLanguageValue('web.操作')"
              width="100"
              fixed="right"
            >
              <template #default="scope">
                <el-button
                  type="success"
                  size="small"
                  @click="DeleteOrderItem(scope.row)"
                >
                  {{ tools.GetLanguageValue("web.删除") }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="height: 160px; padding: 5px">
          <el-form ref="form" label-width="80px">
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.订单号')"
                  label-width="100"
                >
                  <el-input v-model="MakertOpt.OrderNumber" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.支付状态')"
                  label-width="100"
                >
                  <el-input
                    v-model="MakertOpt.PaySatausFormate"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.金额')"
                  label-width="100"
                >
                  <el-input
                    v-model="MakertOpt.AmountFormate"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.税额')"
                  label-width="100"
                >
                  <el-input
                    v-model="MakertOpt.TaxAmountFormate"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.合计')"
                  label-width="100"
                >
                  <el-input
                    v-model="MakertOpt.TotalFormate"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.已支付金额')"
                  label-width="100"
                >
                  <el-input v-model="MakertOpt.PaidTotal" disabled></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.创建人')"
                  label-width="100"
                >
                  <el-input
                    v-model="MakertOpt.CreateUserName"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.订单类型')"
                  label-width="100"
                >
                  <el-select placeholder="Select" v-model="MakertOpt.OrderType">
                    <el-option
                      v-for="item in OrderTypes"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    >
                      {{ item.Value }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="tools.GetLanguageValue('web.客户')"
                  label-width="100"
                >
                  <el-input
                    disabled="true"
                    v-model="MakertOpt.ClientName"
                    @click="ClientWindow = true"
                  >
                    <template #append>
                      <el-button @click="OpenClientWindow">
                        <el-icon><Search /></el-icon>
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div
          style="
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="display: flex">
            <el-button
              type="primary"
              style="width: 100px; margin-left: 50px"
              @click="OpenMarketReceipt"
            >
              <el-icon class="el-icon--right">
                <svg
                  t="1711137821774"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="7771"
                  width="200"
                  height="200"
                >
                  <path
                    d="M819.2 358.4c-16.384 0.512-29.184 14.336-29.184 30.72s12.8 30.208 29.184 30.72c16.384-0.512 29.184-14.336 29.184-30.72s-12.8-29.696-29.184-30.72zM665.6 757.76H358.4c-11.264-0.512-22.016 5.12-27.648 14.848-5.632 9.728-5.632 22.016 0 31.744 5.632 9.728 16.384 15.36 27.648 14.848h307.2c11.264 0.512 22.016-5.12 27.648-14.848 5.632-9.728 5.632-22.016 0-31.744-6.144-9.728-16.384-15.36-27.648-14.848z m-307.2-61.44h307.2c8.192 0 15.872-3.072 22.016-8.704 5.632-5.632 8.704-13.824 8.704-22.016 0-8.192-3.072-15.872-8.704-21.504s-13.312-9.216-21.504-8.704H358.4c-11.264-0.512-22.016 5.12-27.648 14.848-5.632 9.728-5.632 22.016 0 31.744 5.632 9.216 16.384 14.848 27.648 14.336z"
                    p-id="7772"
                    fill="#ffffff"
                  ></path>
                  <path
                    d="M911.36 235.52H788.48V112.64c0-16.384-6.656-31.744-18.432-43.52-11.264-11.264-27.136-17.92-43.008-17.92H296.96c-33.792 0-61.44 27.648-61.44 61.44v122.88H112.64c-32.768 0-59.392 25.6-61.44 57.856v314.88c2.048 32.256 28.672 57.856 61.44 57.856h92.16v245.76c0 33.792 27.136 61.44 60.928 61.44h491.52c33.792 0 61.44-27.648 61.44-61.44v-245.76h92.16c33.792 0 61.44-27.648 61.44-61.44V296.96c0.512-33.792-27.136-61.44-60.928-61.44z m-614.4-92.16c0-8.192 3.072-15.872 8.704-21.504s13.824-9.216 22.016-9.216h368.64c8.192 0 15.872 3.072 22.016 8.704 5.632 5.632 9.216 13.824 9.216 22.016v92.16H296.96v-92.16z m460.288 737.28c0 8.192-3.072 15.872-8.704 21.504s-13.824 9.216-22.016 9.216H296.96c-8.192 0-15.872-3.072-22.016-8.704-5.632-5.632-9.216-13.824-9.216-22.016v-307.2c0-16.896 13.824-30.72 31.232-30.72h430.08c16.896 0 30.72 13.824 30.72 30.72v307.2z m154.112-307.2c0 8.192-3.584 15.872-9.216 21.504s-13.312 9.216-21.504 9.216H819.2v-61.44c0-16.384-6.656-31.744-17.92-43.52-11.776-11.264-27.136-17.92-43.52-17.92H266.24c-34.304 0-61.44 27.648-61.44 61.44v61.44h-61.44c-16.896 0-30.72-13.824-30.72-30.72V327.68c0-16.896 13.824-30.72 30.72-30.72h737.28c16.896 0 30.72 13.824 30.72 30.72v245.76z"
                    p-id="7773"
                    fill="#ffffff"
                  ></path>
                </svg>
              </el-icon>

              {{ tools.GetLanguageValue("web.打印") }}</el-button
            >

            <el-button type="warning" style="width: 100px" @click="HangUpOrder">
              <el-icon class="el-icon--right"><Link /></el-icon>
              {{ tools.GetLanguageValue("web.挂单") }}</el-button
            >
          </div>

          <el-button
            style="
              width: 170px;
              height: 45px;
              margin-right: 20px;
              font-size: 15pt;
            "
            type="primary"
            @click="OpenPaymentWindow"
          >
            <el-icon class="el-icon--right">
              <svg
                t="1711137745433"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="5611"
                width="200"
                height="200"
              >
                <path
                  d="M512 1024C229.286957 1024 0 794.713043 0 512S229.286957 0 512 0s512 229.286957 512 512-229.286957 512-512 512z m0-979.478261C253.773913 44.521739 44.521739 253.773913 44.521739 512s209.25217399 467.478261 467.478261 467.478261 467.478261-209.25217399 467.478261-467.478261S770.226087 44.521739 512 44.521739z"
                  fill="#ffffff"
                  p-id="5612"
                ></path>
                <path
                  d="M705.669565 670.05217401c-26.713043-8.904348-48.973913 2.226087-60.104348 28.93912999-20.034783 51.2-51.2 69.008696-120.20869501 69.008696-80.13912999 0-135.791304-53.426087-158.05217399-149.14782599h198.121739c24.486957 0 37.84347799-15.582609 37.843478-37.84347801 0-24.486957-13.35652199-37.84347799-37.843478-37.843479H356.173913v-17.80869499-26.713044h249.321739c24.486957 0 37.84347799-15.582609 37.84347799-40.06956501s-13.35652199-37.84347799-37.84347799-37.843478H369.530435c24.486957-89.04347799 80.13912999-140.24347801 153.6-140.243478 64.55652201 0 97.94782601 20.034783 120.20869501 77.913043 6.678261 17.808696 22.26086999 28.93913001 42.29565299 28.93913001 6.678261 0 13.35652199-2.226087 22.260869-4.45217301 20.034783-8.904348 31.165217-22.26086999 31.16521801-42.295653 0-8.904348-2.226087-15.582609-4.45217401-22.260869-35.617391-86.817391-104.626087-131.33913001-211.478261-131.339131-126.886957 0-222.608696 89.04347799-251.547826 231.513044h-44.52173899c-24.486957 0-37.84347799 13.35652199-37.84347901 37.84347801s13.35652199 37.84347799 37.84347901 37.84347799h35.61739099v44.521739h-33.391304c-24.486957 0-37.84347799 13.35652199-37.84347899 37.84347901s13.35652199 37.84347799 37.84347899 37.84347799h40.06956501c26.713043 153.6 117.982609 242.64347799 253.77391299 242.64347801 109.078261 0 175.86087001-40.069565 213.704348-131.33913001 2.226087-6.678261 4.45217401-11.130435 4.452174-17.808696 0-17.808696-17.808696-33.391304-35.617392-37.84347799z"
                  fill="#ffffff"
                  p-id="5613"
                ></path>
              </svg>
            </el-icon>
            {{ tools.GetLanguageValue("web.支付") }}</el-button
          >
        </div>
      </div>
      <div
        style="
          width: 345px;
          background-color: #fff;
          border: 1px solid #eee;
          height: 100%;
        "
      >
        <div
          style="height: 450px"
          :style="{ height: !TouchPattern ? '110px' : '455px' }"
        >
          <div style="width: 340px; margin-top: 5px">
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                height: 50px;
                align-items: center;
                gap: 5px;
              "
            >
              <input
                v-model="ScreenText"
                @focus="InputSelect"
                style="
                  font-size: 12pt;
                  height: 45px;
                  border: 0px;
                  border: 1px #eee solid;
                  text-indent: 10px;
                "
                id="ScreenInput"
                ref="ScreenInput"
                :style="{
                  width: TouchPattern
                    ? 'calc(100% - 10px)'
                    : 'calc(100% - 97px)',
                }"
                @keyup.enter="CreateTempProduct()"
              />
              <div
                v-if="!TouchPattern"
                style="
                  width: 80px;
                  height: 50px;
                  font-size: 20pt;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #eee;
                "
                @click="GetGoods(100)"
              >
                <el-icon><Search /></el-icon>
              </div>
            </div>
          </div>

          <div style="width: 340px; margin-top: 5px">
            <div
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                height: 50px;
                align-items: center;
                gap: 5px;
              "
            >
              <div
                class="input_items"
                style="width: 165px"
                @click="CreateTempProduct"
              >
                <el-icon><Sell /></el-icon>
              </div>

              <el-tooltip
                v-if="!Display"
                class="box-item"
                effect="dark"
                :content="tools.GetLanguageValue('web.锁定输入栏')"
                placement="bottom-end"
              >
                <div
                  class="input_items"
                  :style="{ width: TouchPattern ? '165px' : '80px' }"
                  @click="LockBarCode"
                >
                  <!-- 锁定 -->
                  <el-icon><ScaleToOriginal /></el-icon>
                  <el-icon color="red" size="9pt" v-if="LockScreenInputFocus"><Lock /></el-icon>
                </div>
              </el-tooltip>

              <div
                class="input_items"
                v-if="!TouchPattern"
                @click="UnfoldKeyboard()"
              >
                <el-icon><Download /></el-icon>
              </div>
            </div>
          </div>

          <div
            v-if="TouchPattern"
            style="
              width: 340px;
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div style="width: 255px">
              <div class="keyboard">
                <button class="keyboard_item" @click="TouchInput(7)">7</button>
                <button class="keyboard_item" @click="TouchInput(8)">8</button>
                <button class="keyboard_item" @click="TouchInput(9)">9</button>
              </div>
              <div class="keyboard">
                <button class="keyboard_item" @click="TouchInput(4)">4</button>
                <button class="keyboard_item" @click="TouchInput(5)">5</button>
                <button class="keyboard_item" @click="TouchInput(6)">6</button>
              </div>
              <div class="keyboard">
                <button class="keyboard_item" @click="TouchInput(1)">1</button>
                <button class="keyboard_item" @click="TouchInput(2)">2</button>
                <button class="keyboard_item" @click="TouchInput(3)">3</button>
              </div>
              <div class="keyboard">
                <button class="keyboard_item" @click="TouchInput(0)">0</button>
                <button class="keyboard_item" @click="TouchInput('.')">
                  .
                </button>
                <button class="keyboard_item input_items">
                  <el-icon><Setting /></el-icon>
                </button>
              </div>
            </div>

            <div style="width: 80px; margin-top: 5px">
              <button
                class="input_items"
                style="height: 80px"
                @click="BackTouchInput()"
              >
                <el-icon><Back /></el-icon>
              </button>
              <button
                class="input_items"
                @click="FoldKeyboard()"
                style="margin-top: 5px; height: 80px"
              >
                <el-icon><Upload /></el-icon>
              </button>

              <button
                style="
                  width: 80px;
                  height: 165px;
                  margin-top: 5px;
                  border: 0px;
                  font-size: 19pt;
                "
                @click="GetGoods(100)"
              >
                <el-icon><Search /></el-icon>
              </button>
            </div>
          </div>
        </div>
        <div
          style="height: calc(100% - 455px)"
          :style="{
            height: TouchPattern ? 'calc(100% - 455px)' : 'calc(100% - 110px)',
          }"
        >
          <el-table
            border
            :data="GoodsDatas"
            style="width: 100%; height: 100%"
            @cell-click="handleCellClick"
          >
            <el-table-column type="index" width="30"> </el-table-column>
            <el-table-column
              :label="tools.GetLanguageValue('web.商品名称')"
              width="130"
              prop="Name"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.单价')"
              width="70"
              prop="Price"
            />

            <el-table-column
              :label="tools.GetLanguageValue('web.属性')"
              width="100"
            >
              <template #default="scope">
                <el-tag
                  v-for="attr in scope.row.SkuAttribute"
                  :key="attr"
                  style="margin: 2px"
                >
                  {{ attr.AttributeName }}
                </el-tag>
              </template>
            </el-table-column>

            <!-- <el-table-column
              :label="tools.GetLanguageValue('web.单价')"
              width="80"
              prop="PriceFormate"
            /> -->
          </el-table>
        </div>
      </div>
    </div>

    <!-- 挂起订单列表 -->
    <el-drawer
      v-model="HangUpMakertListWindow"
      direction="rtl"
      size="900px"
      :with-header="false"
      :show-close="false"
    >
      <template #default>
        <div
          style="width: 700px; display: flex; align-items: center; gap: 15px"
        >
          <el-input
            v-model="HangUpMakertPadding.Search"
            @keyup.enter="GetHangUpMakertList()"
          >
            <template #append>
              <el-button @click="GetHangUpMakertList()">
                <el-icon> <Search /> </el-icon>
              </el-button>
            </template>
          </el-input>
        </div>
        <br />
        <div :style="{ height: MakertItemHeight - 220 + 'px' }">
          <div style="width: calc(100% - 15px)">
            <el-table
              border
              :data="HangUpMakertDatas"
              style="width: 100%; height: 100%"
              :height="MakertItemHeight"
            >
              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column
                :label="tools.GetLanguageValue('web.订单号')"
                width="120"
                prop="OrderNumber"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.客户姓名')"
                width="100"
                prop="ClientName"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.合计')"
                width="100"
                prop="TotalFormate"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.已支付')"
                width="100"
                prop="PaidTotal"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.订单状态')"
                width="100"
                prop="OrderStatusFormate"
              >
                <template #default="scope">
                  <el-tag v-if="scope.row.OrderStatus == 0">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 200 已确认 -->
                  <el-tag v-if="scope.row.OrderStatus == 200" type="danger">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 300 已发货 -->
                  <el-tag v-if="scope.row.OrderStatus == 300" type="warning">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 400 已完成 -->
                  <el-tag v-if="scope.row.OrderStatus == 400" type="success">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 100 预定 -->
                  <el-tag v-if="scope.row.OrderStatus == 100" type="info">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                :label="tools.GetLanguageValue('web.数量')"
                width="65"
                prop="ItemCount"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.创建人')"
                width="100"
                prop="CreateUserName"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.税额')"
                width="100"
                prop="TaxAmountFormate"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.金额')"
                width="100"
                prop="AmountFormate"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.创建时间')"
                width="180"
                prop="CreateTimeFormat"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.操作')"
                width="110"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    size="small"
                    @click="
                      HangUpMakertListWindow = false;
                      GetMarketDetail(scope.row.Id);
                    "
                  >
                    {{ tools.GetLanguageValue("web.管理订单") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div
              style="
                margin: 5px;
                height: 50px;
                line-height: 40px;
                display: flex;
                align-items: center;
              "
            >
              <el-pagination
                background
                layout="prev, pager, next"
                @current-change="
                  (s) => {
                    HangUpMakertPadding.Page = s;
                    GetMakertList();
                  }
                "
                :page-size="HangUpMakertPadding.Size"
                :page-sizes="[30, 50, 100]"
                :size-change="
                  (s) => {
                    HangUpMakertPadding.Size = s;
                    GetMakertList();
                  }
                "
                :total="HangUpMakertPadding.Count"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div style="flex: auto">
          <el-button @click="HangUpMakertListWindow = false">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
        </div>
      </template>
    </el-drawer>

    <!-- 订单列表 -->
    <el-drawer
      v-model="MakertListWindow"
      direction="rtl"
      size="900px"
      :with-header="false"
      :show-close="false"
    >
      <template #default>
        <div
          style="width: 700px; display: flex; align-items: center; gap: 15px"
        >
          <el-input
            v-model="MakertPadding.Search"
            @keyup.enter="GetMakertList()"
          >
            <template #append>
              <el-button @click="GetMakertList()">
                <el-icon> <Search /> </el-icon>
              </el-button>
            </template>
          </el-input>
          <!-- <el-button @click="GetGoods()">查询</el-button> -->
        </div>
        <br />
        <div :style="{ height: MakertItemHeight - 220 + 'px' }">
          <div style="width: calc(100% - 15px)">
            <el-table
              border
              :data="MakertDatas"
              style="width: 100%; height: 100%"
              :height="MakertItemHeight"
            >
              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column
                :label="tools.GetLanguageValue('web.订单号')"
                width="120"
                prop="OrderNumber"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.客户姓名')"
                width="100"
                prop="ClientName"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.合计')"
                width="100"
                prop="TotalFormate"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.已支付')"
                width="100"
                prop="PaidTotal"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.订单状态')"
                width="100"
                prop="OrderStatusFormate"
              >
                <template #default="scope">
                  <el-tag v-if="scope.row.OrderStatus == 0">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 200 已确认 -->
                  <el-tag v-if="scope.row.OrderStatus == 200" type="danger">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 300 已发货 -->
                  <el-tag v-if="scope.row.OrderStatus == 300" type="warning">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 400 已完成 -->
                  <el-tag v-if="scope.row.OrderStatus == 400" type="success">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>

                  <!-- 100 预定 -->
                  <el-tag v-if="scope.row.OrderStatus == 100" type="info">
                    {{ scope.row.OrderStatusFormate }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                :label="tools.GetLanguageValue('web.数量')"
                width="65"
                prop="ItemCount"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.创建人')"
                width="100"
                prop="CreateUserName"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.税额')"
                width="100"
                prop="TaxAmountFormate"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.金额')"
                width="100"
                prop="AmountFormate"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.创建时间')"
                width="180"
                prop="CreateTimeFormat"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.操作')"
                width="210"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    size="small"
                    @click="GetMarketDetail(scope.row.Id)"
                  >
                    {{ tools.GetLanguageValue("web.管理订单") }}</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="AccomplishOrder(scope.row)"
                  >
                    {{ tools.GetLanguageValue("web.完成订单") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <div
              style="
                margin: 5px;
                height: 50px;
                line-height: 40px;
                display: flex;
                align-items: center;
              "
            >
              <el-pagination
                background
                layout="prev, pager, next"
                @current-change="
                  (s) => {
                    MakertPadding.Page = s;
                    GetMakertList();
                  }
                "
                :page-size="MakertPadding.Size"
                :page-sizes="[30, 50, 100]"
                :size-change="
                  (s) => {
                    MakertPadding.Size = s;
                    GetMakertList();
                  }
                "
                :total="MakertPadding.Count"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div style="flex: auto">
          <el-button @click="SelectProductWindow = false">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
        </div>
      </template>
    </el-drawer>

    <!-- 打开支付 -->
    <el-dialog
      v-model="PaymentWindow"
      :title="tools.GetLanguageValue('web.付款')"
      :show-close="false"
    >
      <form style="margin: 5px">
        <el-form-item
          :label="tools.GetLanguageValue('web.订单号')"
          label-width="150"
        >
          <el-input v-model="MakertPaymatInfo.OrderNumber" disabled />
        </el-form-item>
        <el-form-item
          :label="tools.GetLanguageValue('web.订单合计')"
          label-width="150"
        >
          <el-input v-model="MakertPaymatInfo.TotalFormate" disabled />
        </el-form-item>
        <el-form-item
          :label="tools.GetLanguageValue('web.已支付合计')"
          label-width="150"
        >
          <el-input v-model="MakertPaymatInfo.PaidTotal" disabled />
        </el-form-item>

        <el-divider content-position="left">
          {{ tools.GetLanguageValue("web.支付") }}
        </el-divider>

        <el-form-item
          :label="tools.GetLanguageValue('web.支付金额')"
          label-width="150"
        >
          <el-input-number
            @focus="InputSelect"
            v-model="MakertPaymatInfo.Amount"
            :min="0"
          />
        </el-form-item>
        <el-form-item
          :label="tools.GetLanguageValue('web.支付方式')"
          label-width="150"
        >
          <el-radio-group v-model="MakertPaymatInfo.Payway" size="large">
            <el-radio-button label="0">
              {{ tools.GetLanguageValue("web.现金") }}</el-radio-button
            >
            <el-radio-button label="100">
              {{ tools.GetLanguageValue("web.信用卡") }}
            </el-radio-button>
            <el-radio-button label="200">
              {{ tools.GetLanguageValue("web.支票") }}
            </el-radio-button>
            <el-radio-button label="300">
              {{ tools.GetLanguageValue("web.转账") }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="tools.GetLanguageValue('web.备注')"
          label-width="150"
        >
          <el-input v-model="MakertPaymatInfo.Remark" />
        </el-form-item>
      </form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="PaymentWindow = false">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
          <el-button @click="OrderPayment" type="primary">
            {{ tools.GetLanguageValue("web.确定") }}</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 选择客户 -->
    <el-dialog
      v-model="ClientWindow"
      :title="tools.GetLanguageValue('web.选择客户')"
      :show-close="false"
    >
      <div style="margin: 10px">
        <div style="display: flex; gap: 10px; height: 40px">
          <el-input
            v-model="ClientPadding.Where.AnyWord"
            @input="GetClient"
            style="margin: 5px 0px"
            :placeholder="tools.GetLanguageValue('web.请输入筛选客户信息')"
          />
          <el-button style="margin: 5px 0px" @click="GetClient" type="primary">
            {{ tools.GetLanguageValue("web.查询") }}
          </el-button>
        </div>
        <el-table
          :data="ClientList"
          style="width: 100%"
          border
          :height="300"
          @row-click="CellClientClick"
        >
          <el-table-column type="index" label="No." />
          <el-table-column
            prop="Name"
            :label="tools.GetLanguageValue('web.客户姓名')"
            width="180"
          />

          <el-table-column
            prop="PhoneNumber"
            :label="tools.GetLanguageValue('web.手机号')"
            width="160"
          />
          <el-table-column
            prop="Email"
            :label="tools.GetLanguageValue('web.邮箱')"
            width="160"
          />
          <el-table-column
            prop="DocNumber"
            :label="tools.GetLanguageValue('web.证件号')"
            width="260"
          />

          <el-table-column
            fixed="right"
            :label="tools.GetLanguageValue('web.操作')"
            width="100"
          >
            <template #default="scope">
              <el-button
                type="danger"
                @click="SelectClient(scope.row)"
                size="small"
              >
                {{ tools.GetLanguageValue("web.选择") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ClientWindow = false" type="danger">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 快速添加 -->
    <el-dialog
      v-model="QuickCreateGoodsWindow"
      :title="tools.GetLanguageValue('web.快速添加')"
      :show-close="false"
    >
      <QuickCreateGoods
        v-model="CreateGoodsOpt"
        @CreateOver="CreateOver"
        @CloseOver="CloseOver"
      />
    </el-dialog>

    <!-- 设置 -->
    <el-dialog
      v-model="StoreSettingsWindow"
      :title="tools.GetLanguageValue('web.设置')"
      :show-close="false"
    >
      <el-form label-width="200px" style="margin: 10px 0px">
        <el-divider content-position="left">{{
          tools.GetLanguageValue("web.税率")
        }}</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item
              :label="tools.GetLanguageValue('web.默认临时商品税率')"
            >
              <el-input-number
                v-model="StoreSettings.DefaultTaxRate"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button @click="UpdateMarketInfo" type="danger">{{
                tools.GetLanguageValue("web.保存")
              }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">{{
          tools.GetLanguageValue("web.打印设置")
        }}</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="tools.GetLanguageValue('web.打印地址')">
              <el-input
                :placeholder="tools.GetLanguageValue('web.打印地址')"
                v-model="PrintSettings.PrintUrl"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="text-align: center">
            ({{ tools.GetLanguageValue("web.本地打印使用") }}:<span
              style="color: red"
              >localhost</span
            >)
          </el-col>
          <el-col :span="12">
            <el-form-item :label="tools.GetLanguageValue('web.打印端口')">
              <el-input-number
                v-model="PrintSettings.PrintPort"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <el-button @click="OpenPrintUrl" type="success">{{
                tools.GetLanguageValue("web.下载打印程序")
              }}</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button @click="UpdatePrintInfo" type="danger">{{
                tools.GetLanguageValue("web.保存")
              }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <span class="dialog-footer"> </span>
      </template>
    </el-dialog>

    <!-- 快捷键 -->
    <el-drawer
      v-model="CheckHintWindow"
      :title="tools.GetLanguageValue('web.快捷键')"
      direction="rtl"
      :size="800"
      :before-close="handleClose"
    >
      <div>
        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.订单管理") }}
          </div>
          <div>F1</div>
        </div>
        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.新建订单") }}
          </div>
          <div>F2</div>
        </div>
        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.添加商品") }}
          </div>
          <div>F3</div>
        </div>

        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.选中输入框") }}
          </div>
          <div>F4</div>
        </div>

        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.结账") }}
          </div>
          <div>F6</div>
        </div>
        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.锁定输入框") }}
          </div>
          <div>F9</div>
        </div>

        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.增加查询的商品") }}
          </div>
          <div>Alt + (1-9)</div>
        </div>

        <div class="checkHintItem">
          <div class="checkHint_item_title">
            {{ tools.GetLanguageValue("web.删除列表的商品") }}
          </div>
          <div>Ctrl + (1-9)</div>
        </div>

        <el-divider content-position="left"
          >{{ tools.GetLanguageValue("web.输入解析") }}
        </el-divider>

        <div class="checkHintItem">
          <div class="checkHint_item_title" style="width: 50px">1.</div>
          <div style="width: 150px">输入 66.6.</div>
          <div>
            Enter =
            {{ tools.GetLanguageValue("web.输入金额后加上.为添加临时商品") }}
            66.66<span style="color: red; font-size: 22pt">.</span>
          </div>
        </div>

        <div class="checkHintItem">
          <div class="checkHint_item_title" style="width: 50px">2.</div>
          <div style="width: 150px">
            {{ tools.GetLanguageValue("web.输入名称或者编号") }}
          </div>
          <div>
            (Alt+S) =
            {{
              tools.GetLanguageValue(
                "web.查询含有名称或者编号的商品"
              )
            }}
          </div>
        </div>

        <div></div>
      </div>
    </el-drawer>

    <el-dialog
      v-model="InputScreenTextWinsow"
      :title="tools.GetLanguageValue('web.请输入金额')"
      width="500"
      @open="InputScreenTextWinsowOpen"
    >
      <div style="padding: 30px 10px">
        <el-input
          v-model="ScreenText"
          :autofocus="InputScreenTextWinsow"
          style="
            font-size: 12pt;
            height: 35px;
            border: 0px;
            border: 1px #eee solid;
            text-indent: 10px;
            width: 100%;
          "
          class="WindowScreenInput"
          ref="WindowScreenInput"
          @keyup.enter="CreateTempProduct()"
        />
      </div>
      <template #footer> </template>
    </el-dialog>

    <!-- 选择出库的项目 -->
    <el-dialog
      v-model="ProductStorageTakeNoteWindow"
      :title="tools.GetLanguageValue('web.出库选择')"
      :show-close="false"
    >
      <div style="margin: 10px">
        <el-table
          :data="ProductStorageTakeNotes"
          style="width: 100%"
          border
          :height="250"
        >
          <el-table-column type="index" label="No." />
          <el-table-column prop="IMEI" label="IMEI" width="180" />
          <el-table-column prop="SN" label="SN" width="160" />
          <el-table-column
            prop="Remarks"
            :label="tools.GetLanguageValue('web.备注')"
            width="260"
          />
          <el-table-column
            prop="StoreSupplierName"
            :label="tools.GetLanguageValue('web.供应商')"
            width="260"
          />

          <el-table-column
            fixed="right"
            :label="tools.GetLanguageValue('web.操作')"
            width="100"
          >
            <template #default="scope">
              <el-button
                type="danger"
                @click="LockinStorag(scope.row)"
                size="small"
              >
                {{ tools.GetLanguageValue("web.选择") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-form ref="form" label-width="80px">
          <el-row style="margin: 5px 0px" gutter="10">
            <el-col :span="12">
              <el-form-item label="IMEI">
                <el-input v-model="StorageOpt.IMEI"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="SN">
                <el-input v-model="StorageOpt.SN"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应商">
                <el-select
                  placeholder="供应商"
                  v-model="StorageOpt.StoreSupplierId"
                >
                  <el-option
                    :label="item.Name"
                    :value="item.Id"
                    v-for="(item, index) in StoreSupplierList"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注">
                <el-input />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-button @click="AutoInsertStock">入库</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="ProductStorageTakeNoteWindow = false"
            type="danger"
          >
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </navigation>
</template>
<script >
import axiosapi from "axios";
import jquery from "jquery";
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import QuickCreateGoods from "../Data/Products/QuickCreateGoods.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {
  Search,
  CirclePlus,
  Back,
  Pointer,
  Link,
  Upload,
  ScaleToOriginal,
  DocumentCopy,
  Download,
  Sell,
  Goods,
  Plus,
  Setting,Lock,
  Box,
  Tickets,
  InfoFilled,
  Bottom,
} from "@element-plus/icons-vue";

export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      ProductOpt: null,
      QuickProductOpts: [], //临时商品
      ScreenText: "",
      CreateGoodsOpt: {
        Name: "",
        MnemonicCode: "",
        ProductType: "",
        Price: 0,
        Code: "",
        IncludeTax: false,
        OldPrice: 0,
        Sort: 0,
        Images: "",
        Description: "",
        Cost: 0,
        MnemonicCode: "",
        BrandId: "",
        GoodsCategoryId: "",
        TaxRate: 10,
        PrecioDeVentas: [],
      },

      MakertOpt: {
        OrderType: 0,
        QuickProducts: [],
      },

      MakertDatas: [],
      MakertDetailWindow: false,
      MakertPadding: {
        Where: {},
        Page: 1,
        Size: 30,
        Count: 0,
      },

      HangUpMakertDatas: [], //挂起的
      HangUpMakertPadding: {
        Where: {},
        Page: 1,
        Size: 30,
        Count: 0,
      },
      HangUpMakertListWindow: false,
      SelectProductWindow: false,
      ProductDatas: [],
      CategoryDatas: [],

      ProductOpts: [], //选择的商品

      OrderTypes: [],
      PaymentWindow: false, //支付窗口

      PayMode: "", //支付方式

      MakertPaymatInfo: {},

      ClientList: [], //客户
      ClientWindow: false,
      ClientPadding: {
        Where: {
          AnyWord: "",
        },
      },
      ContentHeight: 900,
      MakertItemHeight: 900,
      GoodsDatas: [],
      GoodsPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
        Where: {},
      },
      InputText: "",
      TouchPattern: false, //触摸模式
      MakertListWindow: false,
      LockScreenInputFocus: false,//是否锁定
      ScreenInputTimer: null, //定时器
      QuickCreateGoodsWindow: false,
      tools: tools,

      StoreSettingsWindow: false,
      StoreSettings: {},
      PrintSettings: {
        PrintPort: 10086,
        PrintUrl: null,
      }, //打印设置

      CheckHintWindow: false,
      InputScreenTextWinsow: false,

      ProductStorageTakeNotes: [],
      ProductStorageTakeNoteWindow: false,

      PrintSetting: {}, //打印设置
      StorageOpt: {},

      StoreSupplierList: [], //供应商
    };
  },
  name: "App",
  mounted() {
    this.GetOrderTypes();
    this.GetClient();
    this.GetStoreSupplier();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 80;
        this.MakertItemHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 80;
    this.MakertItemHeight = this.clientHeight - 200;

    this.TouchPattern = localStorage.getItem("TouchPattern") == "Yes";
    this.GetSettings();
    this.loadPrintSetting();

    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("keyup", this.handlekeyup);

    try {
      this.PrintSettings = JSON.parse(localStorage.getItem("PrintSettings"));
      if (this.PrintSettings == null) {
        this.PrintSettings = {
          PrintPort: 10086,
          PrintUrl: "localhost",
        };
      }
    } catch (e) {
      this.PrintSettings = {
        PrintPort: 10086,
        PrintUrl: "localhost",
      };
    }
  },

  components: {
    navigation,
    QuickCreateGoods,
    Pointer,Lock,
    Link,
    Plus,
    CirclePlus,
    Search,
    Bottom,
    InfoFilled,
    Back,
    Upload,
    Setting,
    DocumentCopy,
    ScaleToOriginal,
    Download,
    Tickets,
    Box,
    Sell,
    Goods,
  },

  deactivated() {
    clearInterval(this.ScreenInputTimer); // 清除定时器
    this.ScreenInputTimer = null;
    console.log("已删除定时器");
  },
  beforeDestroy() {
    clearInterval(this.ScreenInputTimer); // 清除定时器
    this.ScreenInputTimer = null;

    window.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("keyup", this.handlekeyup);
  },
  methods: {
    HangUpOrder() {
      axios.apiMethod(
        "/stores/WorkbenchMarket/HangUpOrder",
        "post",
        this.MakertOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.MakertOpt = {
              OrderType: 0,
              QuickProducts: [],
            };
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //供应商
    GetStoreSupplier() {
      axios.apiMethod(
        "/stores/Data/GetStoreSupplier",
        "get",
        this.StorageOpt,
        (result) => {
          this.StoreSupplierList = result.Data;
        }
      );
    },
    //自动增加库存
    AutoInsertStock() {
      this.StorageOpt.Quantity = 1;
      axios.apiMethod(
        "/stores/Storage/AutoInsertStock",
        "post",
        this.StorageOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.ProductStorageTakeNoteWindow = false;
            this.GetStoragRecordByProductId({ Id: this.StorageOpt.ProductId });
            this.StorageOpt = {};
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //使用库存添加
    LockinStorag(item) {
      var param = {
        makertId: this.MakertOpt.Id,
        storageTakeNoteId: item.Id,
      };

      axios.apiMethod(
        "/stores/WorkbenchMarket/LockinStoragInsertOrder",
        "get",
        param,
        (result) => {
          if (result.Data.IsSuccess) {
            this.ProductStorageTakeNoteWindow = false;
            this.QuickProductOpts = [];
            this.ProductOpts = [];
            this.GetMarketDetail(result.Data.Model);
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //打开下载地址
    OpenPrintUrl() {
      var url = axios.GetUrl();
      window.open(url + "/download/VisionTec.zip");
    },
    //修改打印设置
    UpdatePrintInfo() {
      if (!(this.PrintSettings.PrintPort > 0)) {
        ElMessageBox({
          title: "提示",
          message: tools.GetLanguageValue("web.请输入端口"),
          type: "error",
        });
        return;
      }
      if (
        this.PrintSettings.PrintUrl == "" ||
        this.PrintSettings.PrintUrl == null ||
        this.PrintSettings.PrintUrl == undefined
      ) {
        ElMessageBox({
          title: "提示",
          message: tools.GetLanguageValue("web.请输入地址"),
          type: "error",
        });
        return;
      }

      localStorage.setItem("PrintSettings", JSON.stringify(this.PrintSettings));
    },
    handleCellClick(row, column, cell, event) {
      this.StorageOpt.ProductId = row.Id;

      if (row.ProductType == 100) {
        //如果是手机，那么去查询库存
        this.GetStoragRecordByProductId(row);
      } else {
        //如果不是手机，那么直接添加
        this.AddGoods(row);
      }
    },

    //获取可用库存
    GetStoragRecordByProductId(item) {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetStoragRecordByProductId",
        "get",
        { productId: item.Id },
        (result) => {
          this.ProductStorageTakeNotes = result.Data;
          this.ProductStorageTakeNoteWindow = true;
        }
      );
    },
    InputScreenTextWinsowOpen() {
      this.$nextTick(() => {
        this.$refs.WindowScreenInput.focus();
      });
    },
    //抬起事件
    handlekeyup(e) {
      var numbers = "123456789";
      // 添加查询;
      if (numbers.indexOf(e.key) > -1 && e.altKey == true) {
        window.event.preventDefault(); //

        if (this.GoodsDatas.length == 0) {
          return;
        }

        var index = Number(e.key);
        if (this.GoodsDatas.length >= index) {
          this.AddGoods(this.GoodsDatas[index - 1]);
        }
      }

      // 删除;
      if (numbers.indexOf(e.key) > -1 && e.ctrlKey == true) {
        window.event.preventDefault(); //

        if (this.MakertOpt.OrderItems.length == 0) {
          return;
        }

        var index = Number(e.key);
        if (this.MakertOpt.OrderItems.length >= index) {
          this.DeleteOrderItem(this.MakertOpt.OrderItems[index - 1]);
        }
      }

      // 查询;
      if (e.key.toLowerCase() == "s" && e.altKey == true) {
        window.event.preventDefault(); //
        this.GetGoods(100);
      }

      if (e.key.toLowerCase() == "alt") {
        window.event.preventDefault(); //
      }
    },
    //按下事件
    handleKeydown(e) {
      var numbers = "123456789";
      if (numbers.indexOf(e.key) > -1 && e.ctrlKey == true) {
        window.event.preventDefault(); //
      }

      // 订单管理;
      if (e.key == "F1") {
        window.event.preventDefault(); //
        this.OpenMakertListWindow();
      }

      // 处理结账;
      if (e.key == "F6") {
        window.event.preventDefault(); //
        this.OpenPaymentWindow();
      }

      // 新建订单;
      if (e.key == "F2") {
        window.event.preventDefault(); //
        this.MakertOpt = {
          OrderType: 0,
          QuickProducts: [],
        };
      }

      // 添加商品;
      if (e.key == "F3") {
        window.event.preventDefault(); //
        this.OpenQuickCreateGoodsWindow();
      }

      // 选中输入框;
      if (e.key == "F4") {
        window.event.preventDefault(); //
        document.getElementById("ScreenInput").focus();
      }

      // 锁定输入框;
      if (e.key == "F9") {
        window.event.preventDefault(); //
        this.LockBarCode();
      }

      // 查询;
      if (e.key.toLowerCase() == "s" && e.altKey == true) {
        window.event.preventDefault(); //
      }

      console.log(
        e.key +
          ",ctrlKey " +
          e.ctrlKey +
          ",altKey " +
          e.altKey +
          ",shiftKey " +
          e.shiftKey
      );
    },

    OpenClientWindow() {
      if (
        this.MakertOpt != null &&
        this.MakertOpt.QuickProducts == null &&
        this.MakertOpt.OrderItems.length > 0
      ) {
        this.ClientWindow = true;
        return;
      }
      ElMessage({
        message: tools.GetLanguageValue("web.请先添加商品"),
        type: "error",
      });
    },
    CellClientClick(row, column, cell, event) {
      this.SelectClient(row);
    },

    //打开打印页面
    OpenMarketReceipt() {
      this.PrintSettings = JSON.parse(localStorage.getItem("PrintSettings"));
      if (this.PrintSettings == null) {
        this.PrintSettings = {
          PrintPort: 10086,
          PrintUrl: "localhost",
        };
      }
      this.MakertOpt.PrintSetting = this.PrintSetting;
      this.MakertOpt.LanguageInfo = {
        Name: tools.GetLanguageValue("web.名称"),
        Count: tools.GetLanguageValue("web.数量"),
        Price: tools.GetLanguageValue("web.单价"),
        Total: tools.GetLanguageValue("web.合计"),
        OrderTotal: tools.GetLanguageValue("web.订单合计"),
        OrderAmount: tools.GetLanguageValue("web.订单金额"),
        TaxAmount: tools.GetLanguageValue("web.税额"),
        PaidTotal: tools.GetLanguageValue("web.已支付"),
        ClientTaxNumber: tools.GetLanguageValue("web.税号"),
        Email: tools.GetLanguageValue("web.邮箱"),
        PhoneNumber: tools.GetLanguageValue("web.手机号"),
        Address: tools.GetLanguageValue("web.地址"),
        ClientName: tools.GetLanguageValue("web.姓名"),
      };

      jquery.post(
        "http://" +
          this.PrintSettings.PrintUrl +
          ":" +
          this.PrintSettings.PrintPort +
          "/api/PrintService/PrintNotification",
        this.MakertOpt,
        function (res) {
          console.log(res);
        }
      );
    },

    loadPrintSetting() {
      axios.apiMethod(
        "/stores/Setting/GetPrintSetting",
        "get",
        {},
        (result) => {
          this.PrintSetting = result.Data;
        }
      );
    },
    //修改订单行
    UpdateMarketOrderItem(item) {
      axios.apiMethod(
        "/stores/WorkbenchMarket/UpdateMarketOrderItem",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetMarketDetail(item.OrderId);
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },

    //保存销售相关的配置
    UpdateMarketInfo() {
      axios.apiMethod(
        "/stores/Setting/UpdateMarketInfo",
        "post",
        this.StoreSettings,
        (result) => {
          if (result.Data.IsSuccess) {
            this.StoreSettingsWindow = false;
            ElMessage({
              message: tools.GetLanguageValue("web.操作成功"),
              type: "success",
            });
          } else {
            ElMessage({
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //获取配置
    GetSettings() {
      axios.apiMethod(
        "/stores/Setting/GetStoreSettings",
        "get",
        {},
        (result) => {
          this.StoreSettings = result.Data;
        }
      );
    },
    //展开键盘
    FoldKeyboard() {
      this.TouchPattern = false;
      localStorage.setItem("TouchPattern", "NO");
    },
    //展开键盘
    UnfoldKeyboard() {
      this.TouchPattern = true;
      localStorage.setItem("TouchPattern", "Yes");
    },

    GoPage(url) {
      this.$router.push({
        path: url,
        query: {
          s: Date.now(),
        },
      });
    },
    CurrentChange(number) {
      this.MakertPadding.Page = number;
      this.GetMakertList();
    },
    //关闭回调
    CloseOver(item) {
      this.QuickCreateGoodsWindow = false;
    },
    //创建完成回调
    CreateOver(item) {
      this.QuickCreateGoodsWindow = false;
      this.ProductOpts.push(item.ProductId);
      //如果是电子产品，那么用锁定库存增加
      if (item.ProductType == 100) {
        this.LockinStorag({ Id: item.StorageTakeNoteId });
      } else {
        this.AddMakertDetail();
      }
    },
    //快速添加
    OpenQuickCreateGoodsWindow() {
      this.CreateGoodsOpt = {
        Name: "",
        MnemonicCode: "",
        ProductType: 0,
        Price: 0,
        Code: "",
        BarCode: this.ScreenText,
        IncludeTax: false,
        OldPrice: 0,
        Sort: 0,
        Images: "",
        Description: "",
        Cost: 0,
        MnemonicCode: "",
        BrandId: "",
        GoodsCategoryId: "",
        TaxRate: 21,
        PrecioDeVentas: [],
      };

      this.QuickCreateGoodsWindow = true;
    },
    //锁定扫码
    LockBarCode() {
      this.$refs.ScreenInput.focus();
      this.LockScreenInputFocus = !this.LockScreenInputFocus;

      if (this.ScreenInputTimer == null) {
        this.ScreenInputTimer = setInterval(() => {
          if (this.LockScreenInputFocus) {
            this.$refs.ScreenInput.focus();
            console.log("正在运行定时器");
          } else {
            console.log("正在运行定时器,不锁定焦点");
          }
          // 这里写需要执行的操作或者调用的函数
        }, 1000);
      }
    },
    //删除订单行
    DeleteOrderItem(item) {
      axios.apiMethod(
        "/stores/WorkbenchMarket/DeleteOrderItem",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetMarketDetail(item.OrderId);
          } else {
            ElMessageBox({
              title: "提示",
              message: "修改失败",
              type: "error",
            });
          }
        }
      );
    },
    //打开订单列表
    OpenMakertListWindow() {
      this.MakertListWindow = true;
      this.GetMakertList();
    },
    GetHangUpMakertList() {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetHangUpMakertList",
        "post",
        this.HangUpMakertPadding,
        (result) => {
          this.HangUpMakertDatas = result.Data.Datas;
          this.HangUpMakertPadding.Page = result.Data.Page;
          this.HangUpMakertPadding.Size = result.Data.Size;
          this.HangUpMakertPadding.Count = result.Data.Count;
          this.HangUpMakertPadding.Search = "";

          this.HangUpMakertListWindow = true;
        }
      );
    },
    //获取销售单列表
    GetMakertList() {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetOrderList",
        "post",
        this.MakertPadding,
        (result) => {
          this.MakertDatas = result.Data.Datas;
          this.MakertPadding.Page = result.Data.Page;
          this.MakertPadding.Size = result.Data.Size;
          this.MakertPadding.Count = result.Data.Count;
          this.MakertPadding.Search = "";
        }
      );
    },
    //添加商品
    AddGoods(item) {
      this.ProductOpts = [];
      this.ProductOpts.push(item.Id);
      ElMessage({
        message: "添加" + item.Name,
        type: "success",
      });
      this.AddMakertDetail();
    },
    //创建临时商品
    CreateTempProduct() {
      if (
        this.ScreenText != null &&
        (this.ScreenText.indexOf(".") == -1 || this.ScreenText.length > 9)
      ) {
        //如果大于9位，那么按照扫描枪处理
        this.GetGoods();
        return;
      }

      if (this.ScreenText > 0) {
        ElMessage({
          message: "创建临时商品，商品价格为" + this.ScreenText,
          type: "success",
        });

        this.QuickProductOpts.push({
          Price: this.ScreenText,
        });

        this.AddMakertDetail();
        this.ScreenText = "";

        this.InputScreenTextWinsow = false;
      } else {
        this.InputScreenTextWinsow = true;
      }
    },
    //创建销售单
    AddMakertDetail(storageTakeNoteId) {
      var param = {
        Id: this.MakertOpt.Id,
        ProductIds: this.ProductOpts,
        QuickProducts: this.QuickProductOpts,
        StorageTakeNoteId: storageTakeNoteId,
      };

      axios.apiMethod(
        "/stores/WorkbenchMarket/InsertOrder",
        "post",
        param,
        (result) => {
          if (result.Data.IsSuccess) {
            this.MakertListWindow = false;
            this.QuickProductOpts = [];
            this.ProductOpts = [];
            this.GetMarketDetail(result.Data.Model);
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //获取销售单详细
    GetMarketDetail(orderId) {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/GetMarketDetail",
        "get",
        {
          orderId: orderId,
        },

        (result) => {
          if (result.Data.IsSuccess) {
            this.MakertOpt = result.Data.Model;
            this.MakertListWindow = false;
          }
        }
      );
    },
    //输入减一
    BackTouchInput() {
      this.ScreenText = this.ScreenText.substring(
        0,
        this.ScreenText.length - 1
      );
    },
    ///触摸键盘输入
    TouchInput(val) {
      this.ScreenText += val;
    },
    InputSelect(e) {
      e.currentTarget.select();
    },
    //改价
    ChangePrice(OrderItemId, PrecioDeVentaId) {
      const param = {};
      axios.apiMethod(
        "/stores/WorkbenchMarket/ChangePrice",
        "post",
        {
          PrecioDeVentaId: PrecioDeVentaId,
          OrderItemId: OrderItemId,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetMarketDetail(this.MakertOpt.Id);
          } else {
            ElMessageBox({
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    // 获取商品
    GetGoods() {
      const param = {};
      axios.apiMethod(
        "/stores/Data/GetProductSKU",
        "post",
        {
          Size: 10,
          ScreenValue: this.ScreenText,
        },
        (result) => {
          this.GoodsDatas = result.Data.Datas;
          if (this.GoodsDatas.length == 0) {
            ElMessageBox.confirm(
              tools.GetLanguageValue("web.没有查询到相关商品是否创建"),
              tools.GetLanguageValue("web.提示"),
              {
                dangerouslyUseHTMLString: true,
              }
            )
              .then(() => {
                this.OpenQuickCreateGoodsWindow();
                this.ScreenText = "";
              })
              .catch(() => {
                this.ScreenText = "";
              });
            return;
          } else if (this.GoodsDatas.length == 1) {
            //如果只查询到了一个

            this.handleCellClick(this.GoodsDatas[0]);
          }
        }
      );
    },
    //选择客户
    SelectClient(item) {
      axios.apiMethod(
        "/stores/WorkbenchMarket/OrderSetClient",
        "post",
        {
          Id: this.MakertOpt.Id,
          Clientid: item.Id,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            this.ClientWindow = false;
            this.GetMarketDetail(this.MakertOpt.Id);
          }
        }
      );
    },
    //获取客户
    GetClient() {
      axios.apiMethod(
        "/stores/Client/GetList",
        "post",
        this.ClientPadding,
        (result) => {
          this.ClientList = result.Data.Datas;
        }
      );
    },
    //订单支付
    OrderPayment() {
      axios.apiMethod(
        "/stores/WorkbenchMarket/OrderPayment",
        "post",
        this.MakertPaymatInfo,
        (result) => {
          if (result.Data.IsSuccess) {
            if (result.Data.Model != null && result.Data.Model != undefined) {
              if (result.Data.Model.IsOver) {
                this.MakertOpt = {
                  OrderType: 0,
                  QuickProducts: [],
                };
                this.PaymentWindow = false;

                ElMessageBox({
                  title: tools.GetLanguageValue("web.提示"),
                  message: tools.GetLanguageValue("web.订单已完成"),
                  type: "error",
                });
              }
            }
            this.PaymentWindow = false;
            this.GetMarketDetail(this.MakertPaymatInfo.OrderId);
          } else {
            ElMessageBox({
              title: tools.GetLanguageValue("web.提示"),
              message: tools.GetLanguageValue("web.修改失败"),
              type: "error",
            });
          }
        }
      );
    },
    //打开支付页面
    OpenPaymentWindow() {
      this.MakertPaymatInfo = {
        OrderId: this.MakertOpt.Id,
        OrderNumber: this.MakertOpt.OrderNumber,
        TotalFormate: this.MakertOpt.TotalFormate,
        PaidTotal: this.MakertOpt.PaidTotal,
        Amount: this.MakertOpt.Unpaid,
        Unpaid: this.MakertOpt.Unpaid,
        Payway: 0,
        Remark: "",
      };
      this.PaymentWindow = true;
    },
    //完成订单
    AccomplishOrder() {
      ElMessageBox.confirm(
        tools.GetLanguageValue("web.确定要完成") +
          "<span style='color:red;font-size:14pt'>[" +
          this.MakertOpt.OrderNumber +
          "]</span> ?",
        tools.GetLanguageValue("web.提示"),
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/WorkbenchMarket/AccomplishOrder",
            "post",
            this.MakertOpt,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetMarketDetail(item.OrderId);
              } else {
                ElMessageBox({
                  title: tools.GetLanguageValue("web.提示"),
                  message: result.Data.Msg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },
    //删除订单行
    DeleteOrderItem(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        tools.GetLanguageValue("web.提示"),
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/WorkbenchMarket/DeleteOrderItem",
            "post",
            item,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetMarketDetail(item.OrderId);
              } else {
                ElMessageBox({
                  title: tools.GetLanguageValue("web.提示"),
                  message: tools.GetLanguageValue("web.修改失败"),
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },
    //获取订单类型
    GetOrderTypes() {
      const param = {};
      axios.apiMethod("/sys/SystemData/GetOrderTypes", "get", {}, (result) => {
        this.OrderTypes = result.Data;
      });
    },
    //选择
    SelectProduct(item) {
      var index = this.ProductOpts.indexOf(item.Id);
      if (index == -1) {
        this.ProductOpts.push(item.Id);
      } else {
        this.ProductOpts = this.ProductOpts.filter((e) => e != item.Id);
      }
    },

    AddProduct(item) {
      this.ProductOpts = [];
      this.ProductOpts.push(item.Id);
      this.AddMakertDetail();
    },
  },
};
</script>
  
<style scoped lang="less">
.el-button--text {
  margin-right: 15px;
}

.el-select {
  width: 300px;
}

.el-input {
  width: 300px;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.dialog-footer {
  height: 50px;
  position: absolute;
  bottom: 1px;
  width: calc(100% - 30px);
  display: flex;
  justify-items: center;
  justify-content: flex-end;
}

.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.keyboard {
  display: flex;
  gap: 5px;
  margin-top: 5px;
  .keyboard_item {
    background-color: #eee;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20pt;
    border: 0px;
  }
}

.input_items {
  width: 80px;
  height: 50px;
  font-size: 20pt;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  border: 0px;
}

.el-icon--right {
  margin-right: 5px;
  font-size: 15pt;
}
.checkHintItem {
  display: flex;
  gap: 15px;
  justify-items: center;
  align-items: center;
  height: 45px;
  .checkHint_item_title {
    width: 150px;
  }
}
</style>
  